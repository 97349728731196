import { TokenService } from './../token.service';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { GlobalVariablesService } from '../global-variables.service';
import { NotificationsService } from '../notifications/notifications.service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import {
  DialogLayoutDisplay,
  ToastNotificationInitializer,
  ToastPositionEnum
} from '@costlydeveloper/ngx-awesome-popup';
import { AlertService } from '../alert.service';
import { LoaderService } from '../loader/loader.service';
import { AnalyticsService } from '../analytics.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private httpClient: HttpClient,
              private router: Router,
              private location: Location,
              private navCtrl: NavController,
              private storage: Storage,
              private tokenService: TokenService,
              private globalVars: GlobalVariablesService,
              private notifications: NotificationsService,
              public auth: AngularFireAuth,
              private alerts: AlertService,
              private loader: LoaderService,
              private analyticsService: AnalyticsService) {
              }
  private token: string;
  private domain = this.globalVars.getDomain();
  private authenticated = false;
  private user;

  async loginRequest(url: string, credentials: any, obj) {

    const loading = await this.loader.createLoader('Logging in...');
    await loading.present();
    
    this.tokenService.setEmail(credentials.email);
    this.httpClient.post<typeof obj >(this.domain + url + '?ionic=true', credentials).subscribe(
      async res => {
        this.token = res.token;
        this.user = res.user;
        this.tokenService.setToken(res.token);
        await this.storage.set('token', this.token);
        this.authenticated = true;
        
        this.analyticsService.setGoogleId(this.user._id);
        this.analyticsService.setFacebookId(this.user._id);
        
        this.navCtrl.navigateForward('tabs/user/me');

        await loading.dismiss();
      }, async error => {
        this.authenticated = false;
        await this.alerts.presentError('Invalid credentials');
        await loading.dismiss();
      }
    );
  }

  async loginRequestGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const loading = await this.loader.createLoader('Logging in...');
    await loading.present();

    firebase.auth().signInWithPopup(provider).then(res => {

      const uid = res.user.uid;
      this.httpClient.post<{token: any, user: any}>(this.domain + 'firebase/login', {uid}, {
        headers: {
          '_ga': this.analyticsService.getGa(),
          '_fbp': this.analyticsService.getFbp()
        }
      }).subscribe(async res => {
        this.token = res.token;
        this.user = res.user;
        this.tokenService.setToken(res.token);
        await this.storage.set('token', this.token);
        this.authenticated = true;
        
        this.analyticsService.trackGoogleEvent('generate_lead', { 
          "currency": "EUR", 
          "value": "1" 
        });
        this.analyticsService.trackFacebookEvent('Lead', {});

        this.analyticsService.setGoogleId(this.user._id);
        this.analyticsService.setFacebookId(this.user._id);

        this.navCtrl.navigateForward('tabs/user/me');
        loading.dismiss();
      }, error => {
        loading.dismiss();
        this.authenticated = false;
      });

    }, async err => {
      await this.alerts.presentError('Invalid credentials');
    });
  }

  async logoutRequest() {
    // this.presentLoading('Logging out', 1000);
    this.authenticated = false;
    await this.storage.remove('token');
    this.tokenService.setToken('');
    this.navCtrl.navigateForward('tabs/user');
  }

  alreadyLoged(navigate = true) {
    this.storage.get('token').then(async token => {
      this.token = token;
      this.tokenService.setToken(token);

      const loading = await this.loader.createLoader('Logging in...');
      await loading.present();
      
      this.httpClient.get<any>(this.domain + 'users/me?ionic=true', {
        headers: {
          Authorization: 'Bearer ' + token
        },
      }).subscribe(
        res => {
          this.user = res.user;
          this.authenticated = true;
          this.tokenService.setEmail(res.user.email);
          this.notifications.matchUserToToken(this.user._id);

          loading.dismiss();
          if (navigate){
            this.navCtrl.navigateForward('tabs/user/me');
          }
      }, error => {
        this.navCtrl.navigateForward('tabs/user');
        this.authenticated = false;
        loading.dismiss();
      });
    });
  }


  async aleradyLoggedSync(navigate = true){

    const token = await this.storage.get('token');

    this.tokenService.setToken(token);

    try {
      const res = await this.httpClient.get<any>(this.domain + 'users/is-logged?ionic=true', {
        headers: {
          Authorization: 'Bearer ' + token
        },
      }).toPromise()

      this.user = res.user;

      this.analyticsService.setGoogleId(this.user._id);
      this.analyticsService.setFacebookId(this.user._id);
      
      this.authenticated = true;
      this.notifications.matchUserToToken(this.user._id);          
      if (navigate){
        this.navCtrl.navigateForward('tabs/user/me');
      }

    } catch (error) {
      this.authenticated = false;
      this.navCtrl.navigateForward('tabs/user');
    }
    
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const dontNavigateBack = route.data.dontNavigateBack;

    if (dontNavigateBack){
      await this.aleradyLoggedSync(false);
      return true;
    }

    if(!this.authenticated){
      this.navCtrl.navigateForward('tabs/user');
      return false;
    }

    return true;
  }

  GetUser(url: string, obj) {
    return this.httpClient.get<typeof obj>(this.domain + 'users/me?ionic=true', {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    });
  }

  getUser(){
    return this.user;
  }

  async registrationRequest(credentials) {
    const loading = await this.loader.createLoader('Please wait...');
    await loading.present();

    this.httpClient.post <{token: any, user: any}>(this.domain + 'users' + '?ionic=true', credentials, {
      headers: {
        '_ga': this.analyticsService.getGa(),
        '_fbp': this.analyticsService.getFbp()
      }
    }).subscribe(
      res => {
        this.loginRequest('users/login', {
          email: credentials.email,
          password: credentials.password
        }, Object);

        this.analyticsService.trackGoogleEvent('generate_lead', { 
          "currency": "EUR", 
          "value": "1" 
        });
        this.analyticsService.trackFacebookEvent('Lead', {});

        loading.dismiss();
      }, async error => {
        // console.log(error);
        this.authenticated = false;
        if(error.error.includes('email_1 dup key')){
          await this.alerts.presentError('Email already used.');
        } else if(error.error.includes('E-mail is invalid')){
          await this.alerts.presentError('Email is invalid.');
        } else if(error.error.includes('password')){
          await this.alerts.presentError('Invalid password.');
        } else {
          await this.alerts.presentError('Something went wrong. Please check all required fields.');
        }
        loading.dismiss();
      }
    );
  }

  getUserObject(){
    return this.user;
  }

}

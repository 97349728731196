import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private loadingController: LoadingController) { }

  async createLoader(message = 'Loading...', duration = 100000){
    const loading = this.loadingController.create({
      message: `
        <ion-img style='height: 50px;' src='assets/rocket.svg' alt='Please wait...'></ion-img>
        <h5>${message}</h5>
      `,
      duration,
      spinner: null,
      mode: 'md',
      cssClass: 'my-loader'
    });

    return loading;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private token: string;
  private email: string;
  private tokenSubject = new BehaviorSubject<string>('');
  private emailSubject = new BehaviorSubject<string>('');


  constructor() { }

  getToken() {
    return this.token;
  }

  getTokenAsObservable() {
    return this.tokenSubject.asObservable();
  }

  getEmailAsObservable() {
    return this.emailSubject.asObservable();
  }

  setToken(token: string) {
    this.token = token;
    this.tokenSubject.next(token);
  }

  getEmail() {
    return this.email;
  }

  setEmail(email: string) {
    this.email = email;
    this.emailSubject.next(email);
  }
}

import { GlobalVariablesService } from 'src/app/global-variables.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { TokenService } from './token.service';
// import { DomainService } from './login-panel/domain.service';s
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Storage } from '@ionic/storage';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class CRUDService {
  private token: string;
  constructor(private httpClient: HttpClient,
              private location: Location,
              private navCtrl: NavController,
              private tokenService: TokenService,
              private loadingController: LoadingController,
              private globalVars: GlobalVariablesService,
              private alertController: AlertController,
              private modalController: ModalController,
              private alerts: AlertService) {
  }

  private domain = this.globalVars.getDomain();
  request;
  public returnError;

  public Save(url: string, obj, goback = true) {
    if (obj.id) {
      this.UpdateRequest(url, obj, goback);
    } else {
      this.PostRequest(url, obj, goback);
    }
  }

  public async Delete(url, obj) {

    const alert = await this.alertController.create({
      header: 'Alert!',
      message: 'Are you sure you want to <strong>delete</strong> this record?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        }, {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            this.DeleteRequest(url, obj);
          }
        }
      ]
    });
    await alert.present();
    // this.DeleteRequest(url, obj);
  }


  GetAllRequest(url: string, obj, query = {}) {
    query = {
      ...query,
      ionic: true
    };

    this.token = this.tokenService.getToken();
    return this.httpClient.get<typeof obj>(this.domain + url, {
      params: query,
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    });

    // this.httpHeaders.append('token', t)
  }

  // typeof obj is used so the observable returns object of the passed type
  GetRequest(url: string, id: string, obj, query = '') {
    this.token = this.tokenService.getToken();
    if (id !== '') {
      return this.httpClient.get<typeof obj>(this.domain + url + '?id=' + id + '&ionic=true', {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      });
    } else {
      if (query) {
        return this.httpClient.get<typeof obj>(this.domain + url + '?ionic=true&' + query, {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        });
      } else {
        return this.httpClient.get<typeof obj>(this.domain + url + '?ionic=true', {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        });
      }
    }
  }

  GetRequestPlainText(url: string) {
    this.token = this.tokenService.getToken();

    return this.httpClient.get(this.domain + url + '?ionic=true', {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
      responseType: 'text'
    });
  }

  UpdateRequest(url: string, obj, goback, popups = true) {
    // we remove id so that the update router is not mistaken that we want to update the id
    // if we do no do it the update will be declared invalid
    const id = obj.id;
    delete obj.id;

    this.token = this.tokenService.getToken();
    this.httpClient.patch<typeof obj>(this.domain + url + id + '?ionic=true', obj, {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    }).subscribe(
      res => {
        this.GoBack(goback);
        obj.id = id;
        if (popups) {
          this.alerts.presentSuccess('Changes were saved successfully.');
        }
      },
      error => {
        if (popups){
          this.ErrorHandler(error);
        }
      }
    );
  }

  PatchRequestUnhandled(url: string, obj) {
    this.token = this.tokenService.getToken();
    return this.httpClient.patch<typeof obj>(this.domain + url + '?ionic=true', obj, {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    });
  }

  PostRequest(url: string, obj, goback, goHome = false, popups = true) {
    this.token = this.tokenService.getToken();
    this.httpClient.post<typeof obj>(this.domain + url + '?ionic=true', obj, {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    }).subscribe(res => {
      if (goHome){
        this.GoThankYou();
      }
      this.GoBack(goback);
      if (popups){
        this.alerts.presentSuccess('Changes were saved successfully.');
      }
      this.modalController.dismiss({res});
    },
      error => {
        if (popups){
          this.ErrorHandler(error);
        }
      });
  }

  PostRequestUnhandled(url: string, obj, query = '') {
    this.token = this.tokenService.getToken();
    console.log(this.domain + url + '?ionic=true')
    return this.httpClient.post<typeof obj>(this.domain + url  + '?ionic=true' + query, obj, {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    });
  }

  DeleteRequest(url: string, obj, query = '') {
    this.token = this.tokenService.getToken();

    this.httpClient.delete<typeof obj>(this.domain + url + obj.id + '?ionic=true&' + query, {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    }).subscribe(
      res => {
        this.GoBack();
        this.alerts.presentSuccess('Changes were saved successfully.');
      },
      error => this.ErrorHandler(error)
    );
  }

  DeleteRequestObservable(url: string, obj, query = '') {
    this.token = this.tokenService.getToken();

    return this.httpClient.delete<typeof obj>(this.domain + url + obj.id + '?ionic=true&' + query, {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    });
  }

  private GoBack(goback = true): void {
    if (goback) {
      this.location.back();
    }
  }

  private ErrorHandler(error): void {
    let returnError = '';
    console.log(error);
    if (error.error.includes('duplicate key')) {
      returnError = 'Duplicate record';
    } else if (error.error.includes('Password cannot be')) {
      returnError = 'Password cannot be less than 6 characters, nor \'password\'';
    } else if (error.error === 'You have not ordered from that seller.') {
      returnError = 'You have not ordered from that seller.';
    }else if (error.error === 'You have no registration.') {
      returnError = 'You have no registration.';
    }else if (error.error === 'You have no orders from that client.'){
      returnError = 'You have no orders from that client.';
    }else if (error.error === 'Currently we don\'t have your contacts.') {
      returnError = 'Currently we don\'t have your contacts.';
    }else if (error.error.includes('cancel all your subscriptions')){
      returnError = error.error;
    }
    else {
      returnError = 'Make sure You have filled all input fields!';
    }
    this.alerts.presentError(returnError);
  }

  downloadFile(route: string, filename: string = null, query = {}): void {
    query = {
      ...query,
      ionic: true
    };
    this.token = this.tokenService.getToken();
    this.httpClient.get(this.domain + route, {
      responseType: 'blob' as 'json',
      params: query,
      headers: {
        Authorization: 'Bearer ' + this.token
      }
    }).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      if (filename) {
        downloadLink.setAttribute('download', filename);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
    );
  }

  GoThankYou(){
    this.navCtrl.navigateForward('tabs/thank-you');
  }

  GetFileRequest(url: string, obj, query = {}) {
    query = {
      ...query,
      ionic: true
    };

    console.log(this.domain + url);

    this.token = this.tokenService.getToken();
    return this.httpClient.get<typeof obj>(this.domain + url, {
      responseType: 'text' as 'json',
      params: query,
      headers: {
        Authorization: 'Bearer ' + this.token
      },
    });
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { environment } from '../environments/environment';
import {firebase} from '@firebase/app';
import { NotificationsService } from './notifications/notifications.service';
import { FcmService } from './notifications/fcm.service';
import { GdprService } from './gdpr.service';
import { GlobalVariablesService } from './global-variables.service';
import { ThemeService } from './theme.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from './analytics.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private notificationsService: NotificationsService,
    private fcmService: FcmService,
    private gdprService: GdprService,
    private globalVars: GlobalVariablesService,
    public theme: ThemeService,
    private sanitizer: DomSanitizer,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.theme.setTheme();

      this.globalVars.getFavAndTitle();

      this.fcmService.initPush();
      // console.log(location.origin.toString().split(':')[0] + location.origin.toString().split(':')[1]);
    });
  }

  async ngOnInit() {
    firebase.initializeApp(environment.firebaseConfig);
    await this.gdprService.init();

    this.analyticsService.startGoogleTrackerWithId('G-L1W9R1839Y');
    this.analyticsService.startFacebookTrackerWithId('533260975285473');
  
    let page_referrer = ''
    
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.analyticsService.trackGoogleView(this.router.url, page_referrer);
        this.analyticsService.trackFacebookView(this.router.url, page_referrer);
        page_referrer = this.router.url;
      }      
    });

    await this.notificationsService.init();

    this.analyticsService.loadTokens();
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
       await this.notificationsService.requestPermissionPopUp();
    });
  }

  sanitizeLink(link) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }
}

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as Color from 'color';
import { Storage } from '@ionic/storage';
import { CRUDService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public pageLayouts = {
    product: {
      title: 'right',
      gallery: 'left',
      options: 'right',
      infos: 'right',
      description: 'left'
    },
    products: {
      gridStyle: 'column',
      align: 'left',
      title: 'bottom',
      image: 'right',
      filters: 'left',
      description: false,
      price: true,
      showFilters: true
    },
    service: {
      title: 'right',
      gallery: 'left',
      options: 'left',
      infos: 'right',
      description: 'right'
    },
    services: {
      gridStyle: 'column',
      align: 'left',
      title: 'bottom',
      image: 'right',
      filters: 'left',
      description: false,
      price: true,
      showFilters: true
    },
    blogpost: {
      title: 'right',
      gallery: 'left',
      description: 'right'
    },
    blogposts: {
      gridStyle: 'row',
      align: 'left',
      title: 'bottom',
      image: 'right',
      filters: 'left',
      description: false,
      showFilters: true,
      header: true
    },
    videos: {
      gridStyle: 'column',
      align: 'center',
      title: 'bottom',
      video: 'right',
      showTitle: true,
    }
  };

  public fontLinks = {
    headingFontLink: '',
    bodyFontLink: ''
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storage: Storage,
    private crud: CRUDService
  ) {
    storage.get('theme').then(cssText => {
      this.setGlobalCSS(cssText);
    });
  }

  // Override all global variables with a new theme
  setTheme() {
    let theme;
    this.crud.GetFileRequest('get-theme', Object).subscribe(res => {
      theme = JSON.parse(res);
      this.pageLayouts.product = theme.product;
      this.pageLayouts.products = theme.products;
      this.pageLayouts.service = theme.service;
      this.pageLayouts.blogpost = theme.blogpost;
      this.pageLayouts.blogposts = theme.blogposts;
      this.pageLayouts.videos = theme.videos;
      this.fontLinks.bodyFontLink = theme.bodyFontLink;
      this.fontLinks.headingFontLink = theme.headingFontLink;
      // console.log(theme);

      const cssText = CSSTextGenerator(theme);
      this.setGlobalCSS(cssText);
      this.storage.set('theme', cssText);
    });
  }

  // Define a single CSS variable
  setVariable(name, value) {
    this.document.documentElement.style.setProperty(name, value);
  }

  private setGlobalCSS(css: string) {
    this.document.documentElement.style.cssText = css;
  }

  get storedTheme() {
    return this.storage.get('theme');
  }
}

const defaults = {
  primary: '#3880ff',
  secondary: '#0cd1e8',
  tertiary: '#7044ff',
  success: '#10dc60',
  warning: '#ffce00',
  danger: '#f04141',
  dark: '#222428',
  medium: '#989aa2',
  light: '#f4f5f8',
  lightContrast: '#000000',
  headerA: '#f4f5f8',
  headerB: '#f4f5f8',
  footerA: '#222428',
  footerB: '#222428',
  headingFontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', sans-serif',
  bodyFontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', sans-serif',
  headingFontLink: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', sans-serif',
  bodyFontLink: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', sans-serif',
  headerGradientDeg: 0
};

function CSSTextGenerator(colors) {
  colors = { ...defaults, ...colors };

  const {
    primary,
    primary_contrast,
    secondary,
    tertiary,
    success,
    warning,
    danger,
    dark,
    medium,
    light,
    lightContrast,
    headerA,
    headerB,
    footerA,
    footerB,
    bodyFontFamily,
    headingFontFamily,
    headerGradientDeg
  } = colors;

  const shadeRatio = 0.1;
  const tintRatio = 0.1;

  return `
    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};
    --ion-background-color: ${light};
    --ion-text-color: ${dark};
    --ion-toolbar-background-color: ${contrast(light, 0.1)};
    --ion-toolbar-text-color: ${contrast(dark, 0.1)};
    --ion-item-background-color: ${contrast(light, 0.3)};
    --ion-item-text-color: ${contrast(dark, 0.3)};
    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: 56,128,255;
    --ion-color-primary-contrast: ${primary == primary_contrast ? contrast(primary) : primary_contrast};
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};
    --ion-color-primary-tint:  ${Color(primary).lighten(tintRatio)};
    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: 12,209,232;
    --ion-color-secondary-contrast: ${contrast(secondary)};
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade:  ${Color(secondary).darken(shadeRatio)};
    --ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio)};
    --ion-color-tertiary:  ${tertiary};
    --ion-color-tertiary-rgb: 112,68,255;
    --ion-color-tertiary-contrast: ${contrast(tertiary)};
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
    --ion-color-tertiary-tint:  ${Color(tertiary).lighten(tintRatio)};
    --ion-color-success: ${success};
    --ion-color-success-rgb: 16,220,96;
    --ion-color-success-contrast: ${contrast(success)};
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: ${Color(success).darken(shadeRatio)};
    --ion-color-success-tint: ${Color(success).lighten(tintRatio)};
    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: 255,206,0;
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade: ${Color(warning).darken(shadeRatio)};
    --ion-color-warning-tint: ${Color(warning).lighten(tintRatio)};
    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: 245,61,61;
    --ion-color-danger-contrast: ${contrast(danger)};
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: ${Color(danger).darken(shadeRatio)};
    --ion-color-danger-tint: ${Color(danger).lighten(tintRatio)};
    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: 34,34,34;
    --ion-color-dark-contrast: ${contrast(dark)};
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: ${Color(dark).darken(shadeRatio)};
    --ion-color-dark-tint: ${Color(dark).lighten(tintRatio)};
    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: ${contrast(medium)};
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: ${Color(medium).darken(shadeRatio)};
    --ion-color-medium-tint: ${Color(medium).lighten(tintRatio)};
    --ion-color-light: ${light};
    --ion-color-light-rgb: 244,244,244;
    --ion-color-light-contrast: ${lightContrast};
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: ${Color(light).darken(shadeRatio)};
    --ion-color-light-tint: ${Color(light).lighten(tintRatio)};
    --ion-color-headerA: ${headerA};
    --ion-color-headerA-rgb: 244,244,244;
    --ion-color-headerA-contrast: ${contrast(headerA)};
    --ion-color-headerA-contrast-rgb: 0,0,0;
    --ion-color-headerA-shade: ${Color(headerA).darken(shadeRatio)};
    --ion-color-headerA-tint: ${Color(headerA).lighten(tintRatio)};
    --ion-color-headerB: ${headerB};
    --ion-color-headerB-rgb: 244,244,244;
    --ion-color-headerB-contrast: ${contrast(headerB)};
    --ion-color-headerB-contrast-rgb: 0,0,0;
    --ion-color-headerB-shade: ${Color(headerB).darken(shadeRatio)};
    --ion-color-headerB-tint: ${Color(headerB).lighten(tintRatio)};
    --ion-header-gradient-degrees: ${headerGradientDeg}deg;
    --ion-color-footerA: ${footerA};
    --ion-color-footerA-rgb: 244,244,244;
    --ion-color-footerA-contrast: ${contrast(footerA)};
    --ion-color-footerA-contrast-rgb: 0,0,0;
    --ion-color-footerA-shade: ${Color(footerA).darken(shadeRatio)};
    --ion-color-footerA-tint: ${Color(footerA).lighten(tintRatio)};
    --ion-color-footerB: ${footerB};
    --ion-color-footerB-rgb: 244,244,244;
    --ion-color-footerB-contrast: ${contrast(footerB)};
    --ion-color-footerB-contrast-rgb: 0,0,0;
    --ion-color-footerB-shade: ${Color(footerB).darken(shadeRatio)};
    --ion-color-footerB-tint: ${Color(footerB).lighten(tintRatio)};
    --heading-font-family: ${headingFontFamily};
    --body-font-family: ${bodyFontFamily};`;
}

function contrast(color, ratio = 4) {
  color = Color(color);
  return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
}

import { Injectable } from '@angular/core';
import { DialogLayoutDisplay, ToastNotificationInitializer } from '@costlydeveloper/ngx-awesome-popup';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  async presentSuccess(message: string){
    const newToastNotification = new ToastNotificationInitializer();
    newToastNotification.setTitle('Success!');
    newToastNotification.setMessage(message);

    // Choose layout color type
    newToastNotification.setConfig({
        LayoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
        TextPosition: 'center',
    });

    // Simply open the toast
    newToastNotification.openToastNotification$();
  }

  async presentInfo(message: string) {
    const newToastNotification = new ToastNotificationInitializer();
    newToastNotification.setTitle('Info');
    newToastNotification.setMessage(message);

    // Choose layout color type
    newToastNotification.setConfig({
        LayoutType: DialogLayoutDisplay.INFO, // SUCCESS | INFO | NONE | DANGER | WARNING
        TextPosition: 'center',
    });

    // Simply open the toast
    newToastNotification.openToastNotification$();
  }

  async presentError(message: string) {
    const newToastNotification = new ToastNotificationInitializer();
    newToastNotification.setTitle('Warning!');
    newToastNotification.setMessage(message);

    // Choose layout color type
    newToastNotification.setConfig({
        LayoutType: DialogLayoutDisplay.DANGER, // SUCCESS | INFO | NONE | DANGER | WARNING
        TextPosition: 'center',
    });

    // Simply open the toast
    newToastNotification.openToastNotification$();
  }
}

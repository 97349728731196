import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firebase } from '@firebase/app';
import '@firebase/messaging';
import { AlertController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { CRUDService } from '../crud.service';
import { GlobalVariablesService } from '../global-variables.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {

  constructor(
    private crud: CRUDService,
    private http: HttpClient,
    private alertController: AlertController,
    private globalVars: GlobalVariablesService,
    private storage: Storage,
    ){
  }

  public token: string;
  public user: string;
  private matchedUserToToken = false;
  private askedForPermissions = false;

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.serviceWorker.ready.then((registration) => {
        // Don't crash an error if messaging not supported
        if (!firebase.messaging.isSupported()) {
          resolve();
          return;
        }

        const messaging = firebase.messaging();

        // Register the Service Worker
        messaging.useServiceWorker(registration);

        // Initialize your VAPI key
        messaging.usePublicVapidKey(
          environment.firebaseConfig.vapidKey
        );

        // Optional and not covered in the article
        // Listen to messages when your app is in the foreground
        messaging.onMessage((payload) => {
          console.log(payload);
        });            // Optional and not covered in the article
        // Handle token refresh
        messaging.onTokenRefresh(() => {
          messaging.getToken().then(
            (refreshedToken: string) => {
              console.log(refreshedToken);
            }).catch((err) => {
              console.error(err);
            });
        });

        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }

  async requestPermissionPopUp(){
    if(!this.askedForPermissions){
      const notification_perm = await this.storage.get('notification_perm');

      if (notification_perm === true){
        this.askedForPermissions = true;
      }else {
        this.globalVars.getSiteName().subscribe(async site_name => {
          const alert = await this.alertController.create({
            header: '\"' + site_name + '\" would like to send you notifications',
            message: 'Notifications may include alerts, sounds, and icon badges. These can be configured in settings.',
            buttons: [
              {
                text: 'I do not accept',
                role: 'destructive',
                cssClass: 'secondary',
                handler: () => {
                  // this.acceptedGDPR = false;
                  this.askedForPermissions = true;
                  console.log('Confirm Cancel');
                }
              }, {
                text: 'I accept',
                handler: async () => {
                  console.log('Confirm Ok');
                  await this.requestPermission();
                  this.askedForPermissions = true;
                  await this.storage.set('notification_perm', true);
                }
              }
            ]
          });
      
          await alert.present();
        });
      }
    }
  }

  requestPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      // if (!Notification) {
      //   resolve();
      //   return;
      // }
      if (!firebase.messaging.isSupported()) {
        resolve();
        return;
      }
      try {
        const messaging = firebase.messaging();
        // await messaging.requestPermission();
        await Notification.requestPermission().then();

        this.token = await messaging.getToken();
        console.log('User notifications token:', this.token);
        this.crud.PostRequest('push-token', {token: this.token}, false, false, false);
      } catch (err) {
        console.log(err);

        // No notifications granted
      }

      resolve();
    });
  }

  matchUserToToken(user){
    if (!this.matchedUserToToken){
      this.user = user;
      this.crud.UpdateRequest('push-token/', {
        id: this.token,
        user: this.user,
      }, false, false);
      this.matchedUserToToken = true;
    }
  }
}

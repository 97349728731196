import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CRUDService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class GdprService {

  private acceptedGDPR = false;
  constructor(
    private storage: Storage,
    private alertController: AlertController,
    private crud: CRUDService
    ) { }

  async init() {
    if (!this.acceptedGDPR){
      const gdpr = await this.storage.get('gdpr');

      if (gdpr === true){
        this.acceptedGDPR = true;
      }else{
        this.crud.GetRequestPlainText('gdpr-text').subscribe(res => {
          this.presentGDPR(res);
        });
      }
    }
  }

  async presentGDPR(text) {
    const alert = await this.alertController.create({
      header: 'Terms & Conditions',
      message: text,
      buttons: [
        {
          text: 'I do not accept',
          role: 'destructive',
          cssClass: 'secondary',
          handler: () => {
            this.acceptedGDPR = false;
            console.log('Confirm Cancel');
          }
        }, {
          text: 'I accept',
          handler: async () => {
            this.acceptedGDPR = true;
            await this.storage.set('gdpr', true);
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();
  }

}

import { Injectable } from '@angular/core';

declare var gtag;
declare var fbq;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }
  
  private google_id = '';
  private facebook_id = '';
  private ga = '';
  private fbp = '';

  loadTokens(){
    const cookies = document.cookie.split(';');

    for(let i = 0; i < cookies.length; i++){
      if(cookies[i].includes('_ga=')){
        this.ga = cookies[i].split('=')[1];;
      }
      if(cookies[i].includes('_fbp=')){
        this.fbp = cookies[i].split('=')[1];
      }
    }
  }

  getGa(){
    return this.ga;
  }
  
  getFbp(){
    return this.fbp;
  }

  startGoogleTrackerWithId(id) {
    this.google_id = id;
    gtag('config', this.google_id);
  }

  setGoogleId(user_id) {
    gtag('config', this.google_id, {
      'user_id': user_id
    });
  }

  startFacebookTrackerWithId(id) {
    this.facebook_id = id;
    fbq('init', this.facebook_id);
  }

  setFacebookId(user_id) {
    fbq('init', this.facebook_id, {'external_id': user_id});
  }

  trackGoogleView(page_url, page_referrer) {
    gtag('event', 'page_view', {
        'page_location': page_url,
        'page_referrer': page_referrer
      });      
  }

  trackFacebookView(page_url, page_referrer) {
    fbq('track', 'PageView');      
  }

  trackGoogleEvent(event, object) {
    gtag('event', event, object);      
  }

  trackFacebookEvent(event, object) {
    if(!object){
      fbq('track', event);      
    }
    else{
      fbq('track', event, object);      
    }
  }
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const DOMAIN = 'https://max-media.io/';
export const OWN_DOMAIN = 'https://user.max-media.io/';

// export const DOMAIN = 'http://localhost:3001/';
// export const OWN_DOMAIN = 'http://localhost:8100/';

const domain = location.origin.match(/:\/\/(user[0-9]?\.)?(.[^/:]+)/i)[2];
// let domain = location.origin.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
// export const DOMAIN = 'https://' + domain.toString() + '/';
// console.log('DOMAIN:', DOMAIN);
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCPIe5RIlLKju4WwPS5bvsrV0_Y6M0Janw',
    authDomain: 'maxmediasystembuilder.firebaseapp.com',
    databaseURL: 'https://maxmediasystembuilder.firebaseio.com',
    projectId: 'maxmediasystembuilder',
    storageBucket: 'maxmediasystembuilder.appspot.com',
    messagingSenderId: '750552405996',
    appId: '1:750552405996:web:4656baa4e108898c5d12c9',
    measurementId: 'G-XY26L02LDP',
    vapidKey: 'BLzY7zqTUeenL2L8XilVNxykPz1CKs7EtzhTL7NCc_RXVZEVOWzb8MZtmJdzQvNJEkM-n97dJw48rQapskY9pf4'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { GlobalVariablesService } from 'src/app/global-variables.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { DatatableComponent, SortType } from '@swimlane/ngx-datatable';
import { CRUDService } from '../crud.service';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableFilterComponent implements OnInit {

  constructor(private globalVars: GlobalVariablesService, private crud: CRUDService) { }
  // tslint:disable: no-input-rename
  @Input('columns') columns;
  @Input('rows') rows;
  @Input('route') route;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  public domain = this.globalVars.getDomain();
  public currency = this.globalVars.getCurrency();

  ngOnInit() {
  }

  removeStaff(id){
    // this.cr.get(this.domain + 'user/remove_staff/"' + id).subscribe(res => {});
    this.crud.DeleteRequest('user/remove_staff/', {id});
  }

}

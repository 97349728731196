import { HttpClient } from '@angular/common/http';
import { DOMAIN } from './../environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  private domain = DOMAIN;
  private isMarket = false;
  private currency = 'No currency';
  public site_name = new Subject<string>();

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  appTitle: HTMLLinkElement = document.querySelector('#appTitle');

  constructor(private http: HttpClient) { }

  public getIsMarket(){
    return this.isMarket;
  }
  public setIsMarket(isMarket: boolean){
    this.isMarket = isMarket;
  }

  public getDomain(){
    return this.domain;
  }
  public setDomain(domain: string){
    this.domain = domain;
  }

  public getCurrency(){
    return this.currency;
  }

  public async setCurrency(){
    // this.http.get<{currency}>(this.domain + 'payment_methods').toPromise().then(
    //   res => {
    //     this.currency = res.currency;
    //   }, error => {
    //     console.log(error);
    //   }
    // );
    // return response;
    // // this.http.get<{currency}>(this.domain + 'payment_methods').subscribe(
    // //   res => {
    // //     this.currency = res.currency;
    // //   }
    // // );
  }

  public setMarketMode(){
    this.http.get(this.domain + 'mycontacts').subscribe(
      res => {
        this.isMarket = res[0].isMarket;
      }
    );
  }

  public getFavAndTitle(){
    this.http.get(this.domain + 'mycontacts').subscribe(
      res => {
        this.appTitle.innerHTML = res[0].site_name;
        this.site_name.next(res[0].site_name);
        this.favIcon.href = this.domain + 'medias/' + res[0].logo;
      }
    );
  }

  public getSiteName(){
    return this.site_name.asObservable();
  }
}

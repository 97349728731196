import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginService } from './user/login-service.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'medias',
    loadChildren: () => import('./medias/medias.module').then( m => m.MediasPageModule)
  },
  {
    path: 'change-domain-modal',
    loadChildren: () => import('./change-domain-modal/change-domain-modal.module').then( m => m.ChangeDomainModalPageModule)
  },
  {
    path: 'premium',
    loadChildren: () => import('./user/me/premium/premium.module').then( m => m.PremiumPageModule),
    canActivate: [LoginService],
    data: {dontNavigateBack: true}
  },
  {
    path: 'pricing',
    loadChildren: () => import('./user/me/premium/premium.module').then( m => m.PremiumPageModule)
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./user/me/payment-method/payment-method.module').then( m => m.PaymentMethodPageModule),
    canActivate: [LoginService],
    data: {dontNavigateBack: true}
  },
  {
    path: 'invoice',
    loadChildren: () => import('./user/me/invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'record-set',
    loadChildren: () => import('./user/me/dns-zone/record-set/record-set.module').then( m => m.RecordSetPageModule)
  },
  {
    path: 'failed-domain-registration',
    loadChildren: () => import('./failed-domain-registration/failed-domain-registration.module').then( m => m.FailedDomainRegistrationPageModule),
    canActivate: [LoginService],
    data: {dontNavigateBack: true}
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
